<template>
  <footer class="w-full bg-[#F6F5F7]">
    <div class="md:w-full md:flex md:px-[15%] px-[3%] justify-between mx-auto py-14 md:py-14 text-[#0A142F] text-[12px] text-[#0A142F]">
      <div class="md:w-1/3">
        <div class="mb-6">
          <img class="object-cover"
            src="@/assets/img/sns-icon/bluedot.svg"
            alt="블루닷 공식 블로그의 로고">
        </div>
        <div class="my-2 flex gap-x-[20px]">
          <div>
            (주)블루닷에이아이
          </div>
          <div>
            대표: 이성규
          </div>
        </div>
        <div class="my-2">
          사업자등록번호 178-86-02187
        </div>
        <div class="my-2">
          대표 전화: 070-4138-0657
        </div>
        <div class="my-2">
          대표 이메일: bluedot@mediasphere.kr
        </div>
        <div class="my-2">
          서울시 용산구 한강대로 366, 트윈시티 남산 651호
        </div>
        <div class="my-2">
          통신판매업번호 제2023-서울용산-0965호
        </div>
        <div class="sns flex gap-x-6 mt-8 items-center">
          <a class="block" href="https://www.facebook.com/bluedot.so" target="_blank">
            <img class="max-h-[50px] max-w-[50px] object-fit justify-center"
              src="../assets/img/sns-icon/facebook-logo.svg" alt="페이스북 로고" /><img>
          </a>
          <a class="block" href="https://twitter.com/mediasphereinc" target="_blank">
            <img class="max-h-[50px] max-w-[50px] object-fit justify-center"
              src="../assets/img/sns-icon/twitter-logo.svg" alt="트위터 로고" /><img>
          </a>
          <a class="block" href="https://www.instagram.com/bluedotso/" target="_blank">
            <img class="max-h-[50px] max-w-[50px] object-fit justify-center"
              src="../assets/img/sns-icon/instagram-logo.svg" alt="인스타그램 로고" /><img>
          </a>
        </div>
      </div>
      <div class="md:w-1/8 md:mt-0 mt-[50px]">
        <div class="text-[#0A142F] text-[15px] font-[700]">
          Product
        </div>
        <div class="mt-5">
          <a class="block my-3 text-[13px]" href="https://bi.bluedot.so/" target="_blank">블루닷 인텔리전스</a>
          <a class="block my-3 text-[13px]" href="https://cms.bluedot.so/" target="_blank">블루닷 CMS</a>
          <a class="block my-3 text-[13px]" href="https://orwell.bluedot.so/" target="_blank">오웰</a>
          <a class="block my-3 text-[13px]" href="https://sofos.bluedot.so/" target="_blank">소포스</a>
          <a class="block my-3 text-[13px]" href="https://pubai.bluedot.so/" target="_blank">퍼바이</a>
        </div>
      </div>
      <div class="md:w-1/8 md:mt-0 mt-[50px]">
        <div class="text-[#0A142F] text-[15px] font-[700]">
          Resources
        </div>
        <div class="mt-5">
          <a class="block my-3 text-[13px]" href="https://bluedot.so/blog/" target="_blank">블로그</a>
        </div>
      </div>
      <div class="md:w-1/8 md:mt-0 mt-[50px]">
        <div class="text-[#0A142F] text-[15px] font-[700]">
          Company
        </div>
        <div class="mt-5">
          <a class="block my-3 text-[13px]" href="https://bluedot.so/blog/about/" target="_blank">회사 소개</a>
          <a class="block my-3 text-[13px]" href="https://docs.google.com/document/d/1j7qvUR5MyNzT9NJSVAXBeyi0EUDVmDnc1G4Lio4o1js/edit?tab=t.0#heading=h.y02wj9q0drs8" target="_blank">Mission Statement</a>
          <a class="block my-3 text-[13px]" href="https://docs.google.com/document/d/1CYcxdhgysCOW64ygcwwgqPOIpMoz0BwSfm_p79FW7cE/edit?tab=t.0#heading=h.6rapiwdl0hhn" target="_blank">개인정보처리 방침</a>
        </div>
      </div>
      <div class="md:w-1/8 md:mt-0 mt-[50px]">
        <div class="text-[#0A142F] text-[15px] font-[700]">
          Social
        </div>
        <div class="mt-5">
          <a class="block my-3 text-[13px]" href="https://www.youtube.com/@bluedotso" target="_blank">Youtube</a>
          <a class="block my-3 text-[13px]" href="https://www.linkedin.com/company/bluedotso/?viewAsMember=true" target="_blank">Linkedin</a>
          <a class="block my-3 text-[13px]" href="https://www.facebook.com/bluedot.so" target="_blank">Facebook</a>
        </div>
      </div>
    </div>
  </footer>
</template>
